import * as ES6Promise from "es6-promise";
ES6Promise.polyfill();

import Dev from './global/Dev';
import { Header } from './global/Header';
import { HistoryManager } from './andreasoby/controllers/HistoryManager';
import { PageTransition } from './andreasoby/controllers/PageTransition';
import { LazyLoad } from './andreasoby/controllers/Lazyload';
import { Newsletter } from './global/Newsletter';
import { Cursor } from './global/Cursor';
import { WordReveal } from './modules/WordReveal';

interface ModuleToLoad {
  selector: string,
  promise: any
}

//@ts-ignore
window.loadHero = async (): Promise<any> => {
  return await import(/* webpackChunkName: "hero" */ './modules/Hero');
}
//@ts-ignore
window.loadHeroNoMedia = async (): Promise<any> => {
  return await import(/* webpackChunkName: "heroNoMedia" */ './modules/HeroNoMedia');
}
//@ts-ignore
window.loadProjectHero = async (): Promise<any> => {
  return await import(/* webpackChunkName: "projectHero" */ './modules/ProjectHero');
}
//@ts-ignore
window.loadLedeLargeText = async (): Promise<any> => {
  return await import(/* webpackChunkName: "ledeLargeText" */ './modules/LedeLargeText');
}
//@ts-ignore
window.loadText = async (): Promise<any> => {
  return await import(/* webpackChunkName: "text" */ './modules/Text');
}
//@ts-ignore
window.loadTimeline = async (): Promise<any> => {
  return await import(/* webpackChunkName: "timeline" */ './modules/Timeline');
}
//@ts-ignore
window.loadMediaSlideshow = async (): Promise<any> => {
  return await import(/* webpackChunkName: "mediaSlideshow" */ './modules/MediaSlideshow');
}
//@ts-ignore
window.loadQuote = async (): Promise<any> => {
  return await import(/* webpackChunkName: "quote" */ './modules/Quote');
}
//@ts-ignore
window.loadMediaAndCaption = async (): Promise<any> => {
  return await import(/* webpackChunkName: "mediaAndCaption" */ './modules/MediaAndCaption');
}
//@ts-ignore
window.loadTwoMediaItems = async (): Promise<any> => {
  return await import(/* webpackChunkName: "twoMediaItems" */ './modules/TwoMediaItems');
}
//@ts-ignore
window.loadHeadline = async (): Promise<any> => {
  return await import(/* webpackChunkName: "headline" */ './modules/Headline');
}
//@ts-ignore
window.loadCredits = async (): Promise<any> => {
  return await import(/* webpackChunkName: "credits" */ './modules/Credits');
}
//@ts-ignore
window.loadCollapseNext = async (): Promise<any> => {
  return await import(/* webpackChunkName: "collapseNext" */ './modules/CollapseNext');
}
//@ts-ignore
window.loadProjectTeasers = async (): Promise<any> => {
  return await import(/* webpackChunkName: "projectTeasers" */ './modules/ProjectTeasers');
}
//@ts-ignore
window.loadPostHero = async (): Promise<any> => {
  return await import(/* webpackChunkName: "postHero" */ './modules/PostHero');
}
//@ts-ignore
window.loadMedia = async (): Promise<any> => {
  return await import(/* webpackChunkName: "media" */ './modules/Media');
}
//@ts-ignore
window.loadFeaturedNewsItems = async (): Promise<any> => {
  return await import(/* webpackChunkName: "featuredNewsItems" */ './modules/FeaturedNewsItems');
}
//@ts-ignore
window.loadFeaturedNewsItem = async (): Promise<any> => {
  return await import(/* webpackChunkName: "featuredNewsItem" */ './modules/FeaturedNewsItem');
}
//@ts-ignore
window.loadTeaserSmall = async (): Promise<any> => {
  return await import(/* webpackChunkName: "teaserSmall" */ './modules/teasers/TeaserSmall');
}
//@ts-ignore
window.loadPostTeaserLarge = async (): Promise<any> => {
  return await import(/* webpackChunkName: "postTeaserLarge" */ './modules/teasers/PostTeaserLarge');
}
//@ts-ignore
window.loadExternalNewsItems = async (): Promise<any> => {
  return await import(/* webpackChunkName: "externalNewsItems" */ './modules/ExternalNewsItems');
}
//@ts-ignore
window.loadExternalNewsItem = async (): Promise<any> => {
  return await import(/* webpackChunkName: "externalNewsItem" */ './modules/ExternalNewsItem');
}
//@ts-ignore
window.loadSelectedProjects = async (): Promise<any> => {
  return await import(/* webpackChunkName: "selectedProjects" */ './modules/SelectedProjects');
}
//@ts-ignore
window.loadAccordion = async (): Promise<any> => {
  return await import(/* webpackChunkName: "accordion" */ './modules/Accordion');
}
//@ts-ignore
window.loadFullwidthMedia = async (): Promise<any> => {
  return await import(/* webpackChunkName: "fullwidthMedia" */ './modules/FullwidthMedia');
}
//@ts-ignore
window.loadExtendedLinks = async (): Promise<any> => {
  return await import(/* webpackChunkName: "extendedLinks" */ './modules/ExtendedLinks');
}
//@ts-ignore
window.loadNumberedListOfTexts = async (): Promise<any> => {
  return await import(/* webpackChunkName: "numberedListOfTexts" */ './modules/NumberedListOfTexts');
}
//@ts-ignore
window.loadPersons = async (): Promise<any> => {
  return await import(/* webpackChunkName: "persons" */ './modules/Persons');
}
//@ts-ignore
window.loadPersonTeaser = async (): Promise<any> => {
  return await import(/* webpackChunkName: "personTeaser" */ './modules/teasers/PersonTeaser');
}
//@ts-ignore
window.loadPostNext = async (): Promise<any> => {
  return await import(/* webpackChunkName: "postNext" */ './modules/teasers/PostNext');
}
//@ts-ignore
window.loadWordReveal = async (): Promise<any> => {
  return await import(/* webpackChunkName: "wordReveal" */ './modules/WordReveal');
}


class Main {

  private _history_manager: HistoryManager;
  private _page_transition: PageTransition;
  private _lazy_load: LazyLoad;
  private _word_reveal: WordReveal;

  constructor() {

    document.addEventListener('DOMContentLoaded', () => this.init());

  }


  private init() {

    if ( !document.body.classList.contains( 'wp-admin' ) ) {
     
      this._history_manager = new HistoryManager( [ '.c-main__inner', '.c-main-nav__list', '.c-footer .c-main-nav__list' ] );
      this._page_transition = new PageTransition();
      this._lazy_load = new LazyLoad();
      
      this.setupListeners();
      this.setupContent();
      this.checkIfTouch();
    
      new Header();
      // new Dev();
      // new Newsletter();

      // Add custom cursor if user isn't using a touch device
      if ( this.checkIfTouch() ) {
        
        document.body.classList.add( 'touch-device' );
        
      } else {
        
        new Cursor();

      }
      
    }
    
  }


  private checkIfTouch() {

    try {

      document.createEvent("TouchEvent");  
      return true;

    } catch (e) {

      return false;

    }  

  }


  private setupListeners() {

    window.addEventListener( 'ajaxLinkClicked', ( event: CustomEvent ) => {

      this._page_transition.hideContent( event );

    } );

    window.addEventListener( 'ajaxSucceeded', ( event: Event ) => this.ajaxSucceeded( event ) );
    window.addEventListener( 'newContentInserted', () => this.setupContent() );
    window.addEventListener( 'contentUpdated', () => this.updateContent() );
    window.addEventListener( 'contentFullyRevealed', () => this.contentFullyRevealed() );

    window.addEventListener( 'popstate' , ( event: PopStateEvent ) => {
        
        this._page_transition.hideContent();
        this._page_transition.injectContent();

    } );

  }


  private ajaxSucceeded( event: Event ) {

    this._page_transition.injectContent();

  }


  private updateContent() {

    this._history_manager.setupNewLinks();

  }


  private contentFullyRevealed() {

    const word_reveal: NodeListOf<HTMLElement> = document.querySelectorAll( '.c-word-reveal' );
    new WordReveal( word_reveal );

  }
  
  
  private setupContent() {
    
    const modules: Array<ModuleToLoad> = [
      { selector: '.c-hero', promise: 'loadHero' },
      { selector: '.c-hero-no-media', promise: 'loadHeroNoMedia' },
      { selector: '.c-project-hero', promise: 'loadProjectHero' },
      { selector: '.c-lede-large-text', promise: 'loadLedeLargeText' },
      { selector: '.c-text', promise: 'loadText' },
      { selector: '.c-media-slideshow', promise: 'loadMediaSlideshow' },
      { selector: '.c-timeline', promise: 'loadTimeline' },
      { selector: '.c-quote', promise: 'loadQuote' },
      { selector: '.c-media-and-caption', promise: 'loadMediaAndCaption' },
      { selector: '.c-two-media-items', promise: 'loadTwoMediaItems' },
      { selector: '.c-headline', promise: 'loadHeadline' },
      { selector: '.c-credits', promise: 'loadCredits' },
      { selector: '.c-collapse-next', promise: 'loadCollapseNext' },
      { selector: '.c-project-teasers', promise: 'loadProjectTeasers' },
      { selector: '.c-post-hero', promise: 'loadPostHero' },
      { selector: '.c-media', promise: 'loadMedia' },
      { selector: '.c-featured-news-items', promise: 'loadFeaturedNewsItems' },
      { selector: '.c-featured-news-item', promise: 'loadFeaturedNewsItem' },
      { selector: '.c-teaser-small', promise: 'loadTeaserSmall' },
      { selector: '.c-post-teaser-large', promise: 'loadPostTeaserLarge' },
      { selector: '.c-external-news-items', promise: 'loadExternalNewsItems' },
      { selector: '.c-external-news-item', promise: 'loadExternalNewsItem' },
      { selector: '.c-selected-projects', promise: 'loadSelectedProjects' },
      { selector: '.c-accordion', promise: 'loadAccordion' },
      { selector: '.c-fullwidth-media', promise: 'loadFullwidthMedia' },
      { selector: '.c-extended-links', promise: 'loadExtendedLinks' },
      { selector: '.c-numbered-list-of-texts', promise: 'loadNumberedListOfTexts' },
      { selector: '.c-persons', promise: 'loadPersons' },
      { selector: '.c-person-teaser', promise: 'loadPersonTeaser' },
      { selector: '.c-post-next', promise: 'loadPostNext' },
    ];

    const queue: Array<any> = [];
    for ( let i: number = 0; i < modules.length; i++ ) {

      const element: HTMLElement = document.querySelector( modules[ i ].selector );
      //@ts-ignore
      if ( element ) queue.push( window[modules[ i ].promise]() );

    }

    Promise.all( queue ).then( ( modules: any ) => {
      
      for ( let i: number = 0; i < modules.length; i++ ) {
        
        // Get dynamic constructor name
        const name: string = Object.keys(modules[ i ] )[0];
        new modules[ i ][ name ];

      }

      this._lazy_load.updateContent();
      this._page_transition.showContent();

    });

  }

}

new Main();