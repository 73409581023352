export class HistoryManager {

    private _root_url: string = location.protocol + "//" + top.location.host.toString();
    private _loading_url: string;
    private _state_change_on_click: boolean;

    private _request: XMLHttpRequest;
    private ajaxLinkClicked: CustomEvent = document.createEvent( "CustomEvent" );
    private ajaxSucceeded:Event = document.createEvent( "Event" );
    private ajaxFailed: Event = document.createEvent( "Event" );

    private _internal_links_selector: string = "a[href^='" + this._root_url + "'], a[href^='/'], a[href^='./'], a[href^='../'], a[href^='#'] , a[href^='?']";
    private _internal_links_selector_avoid: Array<string>;
    private _internal_links: Array<HTMLAnchorElement>;
    private _content_selectors: Array<string>;

    public response: any;
    public hash: string;

    private _title_element: HTMLTitleElement = document.querySelector( "title" );

    constructor( content_selectors: Array<string>, link_selectors?: string, link_selectors_avoid?: Array<string> ) {
        
        if ( link_selectors ) this._internal_links_selector = link_selectors;
        if ( link_selectors_avoid ) this._internal_links_selector_avoid = link_selectors_avoid;
        this._content_selectors = content_selectors;

        this.ajaxSucceeded.initEvent( "ajaxSucceeded", false, true );
        this.ajaxFailed.initEvent( "ajaxFailed", false, true );

        // Put init page data into history state
        const html_element: HTMLElement = document.querySelector( 'html' );
        history.replaceState( this.stateData( html_element.innerHTML ), document.title, window.location.href );

        this.setupListeners();

    }


    private setupListeners() {

        this.setupNewLinks();

    }


    public setupNewLinks() {

        if ( location.host === "localhost:3000" ) {
            const links: NodeListOf<HTMLAnchorElement> = document.querySelectorAll("a");
            
            for ( let i = 0; i < links.length; i++ ) {
                
                const path: string = links[ i ].href.replace( "//localhost:8080", "//localhost:3000" );
                if ( links[ i ].href !== path ) links[ i ].href = path;

            }

        }
        
        const internal_links_nodelist: NodeListOf<HTMLAnchorElement> = document.querySelectorAll( this._internal_links_selector );
        this._internal_links = Array.prototype.slice.call( internal_links_nodelist );

        for ( let i: number = 0; i < this._internal_links.length; i++ ) {

            if ( this._internal_links_selector_avoid ) {

                for ( let j: number = 0; j < this._internal_links_selector_avoid.length; j++ ) {
                    
                    if ( this._internal_links[ i ].classList.contains( this._internal_links_selector_avoid[ j ] ) ) {

                        this._internal_links[ i ].classList.add( 'no-ajax' );

                    }

                }

            }

            if ( !this._internal_links[ i ].classList.contains( 'no-ajax' ) &&

                !this._internal_links[ i ].classList.contains( 'click-async' ) ) {
                //this._internal_links[i].removeEventListener('click', (event:MouseEvent) => this.internalLinkClicked(event));
                this._internal_links[ i ].classList.add( 'click-async' );
                this._internal_links[ i ].addEventListener( 'click', ( event: MouseEvent) => this.internalLinkClicked( event ), false );

            }

        }
        

        this._state_change_on_click = false;
    }


    private internalLinkClicked( event:MouseEvent ) {

        // console.log('internalLinkClicked');
        if ( event.which == 2 || event.ctrlKey || event.metaKey ) return true;

        const target: HTMLAnchorElement = <HTMLAnchorElement>event.currentTarget;
        let url: string = target.getAttribute( 'href' );
        const link_target: string = target.getAttribute( 'target' );

        this.ajaxLinkClicked = document.createEvent( "CustomEvent" );
        this.ajaxLinkClicked.initCustomEvent( "ajaxLinkClicked", false, true, { target: target } );

        if ( link_target === '_blank' || url.indexOf("sites/default/files") >= 0 || url.indexOf("/search") >= 0 ) return true;

        if ( url == window.location.pathname + window.location.search ) {

            event.preventDefault();
            return false;

        }

        if ( url.indexOf("#") >= 0 ) {
            
            const hash_url: Array<string> = url.split( "#" );
            url = hash_url[ 0 ];
            this.hash = hash_url[ 1 ];

        } else {

            this.hash = null;

        }

        this.appendAjaxParam( url );

        event.preventDefault();
        event.stopPropagation();

        return false;

    }


    private appendAjaxParam( url: string ) {

        this._loading_url = url;

        this._state_change_on_click = true;
        window.dispatchEvent( this.ajaxLinkClicked );

        if ( url.indexOf( "?" ) >= 0 ) {
            
            if ( url.indexOf( "useajax" ) >= 0 ) {

                this.loadPage( url );

            } else {

                this.loadPage( url + "&useajax=true" );
            }

        } else {

            this.loadPage( url + "?useajax=true" );

        }

    }


    private loadPage( url: string ) {

        console.log('loadPage', url);
        if (this._request) this._request.abort();

        this._request = new XMLHttpRequest();
        this._request.addEventListener( 'load', ( event: Event ) => this.loadPageSucceeded( event ) );
        this._request.addEventListener( 'error', ( event: Event ) => this.loadPageFailed( event ) );
        this._request.open( 'GET', url, true );
        this._request.send();

        // history.pushState( null, "Loading", this.sanitizeURL( url ) );

    }


    private loadPageFailed( event:Event ) {

        // console.log('loadPageFailed');
        this._request.removeEventListener( 'load', this.loadPageSucceeded );
        this._request = null;
        window.dispatchEvent( this.ajaxFailed );
        document.location.href = this.sanitizeURL( this._loading_url );

        return false;

    }


    private loadPageSucceeded(event:Event) {

        // console.log('loadPageSucceeded');
        this._request.removeEventListener( 'load', this.loadPageSucceeded );
        this._request = null;
        
        const target:XMLHttpRequest = <XMLHttpRequest>event.target;

        if ( target.status >= 200 && target.status <= 404 ) {

            const response = this.stateData( target.response );

            history.pushState( response, response.title, this.sanitizeURL( this._loading_url ) );

            window.dispatchEvent(this.ajaxSucceeded);

            // Send google analytics pageview
            // @ts-ignore
            // ga('send', {
            //   hitType: 'pageview',
            //   location: document.location.href
            // });

            // Send google tags pageview
            gtag('event', 'page_view', {
                page_title: response.title,
                page_location: this.sanitizeURL( this._loading_url ),
                send_to: 'UA-79884988-1'
            } );

        } else {

            console.log('We reached our target server, but it returned an error');
            window.dispatchEvent(this.ajaxFailed);

        }

    }


    private sanitizeURL( url: string ): string {

        return url.replace("&useajax=true", '').replace("&useajax", '').replace("?useajax=true", '').replace("useajax", '');

    }


    private stateData( response: string ) {

        const response_html:HTMLElement = document.createElement( 'HTML' );
        response_html.innerHTML = response;
        
        let title_text: string = "";
        if ( this._title_element ) {

            const title: HTMLTitleElement = <HTMLTitleElement>response_html.querySelector( 'title' );
            title_text = ( title ) ? title.innerText : "";
            this._title_element.innerText = title_text;

        }

        const body: HTMLBodyElement = <HTMLBodyElement>response_html.querySelector( 'body' );
        const body_classes: string = body.getAttribute( 'class' );

        let markup: Array<string> = [];

        for ( let i: number = 0; i < this._content_selectors.length; i++ ) {

            const selector:string = <string>this._content_selectors[ i ];
            const element: HTMLElement = response_html.querySelector( selector );
            markup.push( element.innerHTML );

        }

        return {
            
            title: title_text,
            bodyClasses: body_classes,
            markup: markup

        };
    }

}