import '../../css/global/cursor.css';

import gsap from "gsap";

export class Cursor {
  
  private _cursor: HTMLElement;
  private _cursor_label: HTMLElement;
  private _cursor_label_text: HTMLElement;
  private _cursor_bg_text: HTMLElement;
  // private _cursor_bg_red: HTMLElement;

  private _cursor_size: number = .1;
  private _cursor_size_expanded: number = .8;

  constructor() {

    this._cursor = document.querySelector( '.c-cursor' );
    if ( this._cursor ) {

      this._cursor_label = this._cursor.querySelector( '.c-cursor__label' );
      this._cursor_label_text = this._cursor.querySelector( '.c-cursor__label__text' );
      this._cursor_bg_text = this._cursor.querySelector( '.c-cursor__bg--text' );
      // this._cursor_bg_red = this._cursor.querySelector( '.c-cursor__bg--red' );

      // gsap.set( [ this._cursor_bg_text, this._cursor_bg_red ], { scale: this._cursor_size } );
      gsap.set( [ this._cursor_bg_text ], { scale: this._cursor_size } );
      gsap.set( this._cursor_label, { opacity: 0 } );

      window.addEventListener( 'mousemove', ( event: MouseEvent ) => this.handleMove( event ) );
      window.addEventListener( 'dragMoveEvent', ( event: CustomEvent ) => this.handleDragMove( event ) );
      window.addEventListener( 'showCursorEvent', () => this.showCursor() );
      window.addEventListener( 'hideCursorEvent', () => this.hideCursor() );
      window.addEventListener( 'changeCursorLabelEvent', ( event: CustomEvent ) => this.changeCursorLabel( event ) );

    }
    
  }


  private showCursor() {

    // gsap.to( [ this._cursor_bg_text ], { duration: .2, backgroundColor: '#ffffff' } );
    // gsap.to( [ this._cursor_bg_text, this._cursor_bg_red ], { duration: .5, scale: this._cursor_size_expanded } );
    gsap.killTweensOf( [ this._cursor_bg_text, this._cursor_label ] );
    gsap.to( [ this._cursor_bg_text ], { duration: .5, scale: this._cursor_size_expanded, backgroundColor: '#ffffff' } );
    gsap.to( this._cursor_label, { duration: .5, delay: .2, opacity: 1 } );

  }


  private hideCursor() {

    // gsap.to( [ this._cursor_bg_text ], { duration: .4, backgroundColor: '#000000' } );
    // gsap.to( [ this._cursor_bg_text, this._cursor_bg_red ], { duration: .4, scale: this._cursor_size } );
    gsap.killTweensOf( [ this._cursor_bg_text, this._cursor_label ] );
    gsap.to( this._cursor_bg_text, { duration: .4, scale: this._cursor_size, backgroundColor: '#000000' } );
    gsap.to( this._cursor_label, { duration: .3, opacity: 0 } );

  }

  private handleDragMove( event: CustomEvent ) {

    this.moveCursor( event.detail.x, event.detail.y );

  }


  private handleMove( event: MouseEvent ) {

    // gsap.set( this._cursor_bg_text, { delay: .2, opacity: 1 } );
    this.moveCursor( event.clientX, event.clientY );
    
  }


  private moveCursor( x: number, y: number ) {
    
    gsap.to( [ this._cursor_bg_text, this._cursor_label ], { duration: .2, x: x, y: y } );
    // gsap.to( this._cursor_bg_red, { duration: .3, x: x, y: y } );

  }


  private changeCursorLabel( event: CustomEvent ) {

    if ( this._cursor_label_text && event.detail.label ) this._cursor_label_text.innerText = event.detail.label;

  }

}