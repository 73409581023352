// import { TweenMax, Power2, Power4, Circ } from 'gsap';
import gsap, { Power2 } from 'gsap';
// @ts-ignore
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";

// @ts-ignore
gsap.registerPlugin( ScrollToPlugin );


export class PageTransition {

    public transitionType: string = '';
    
    private _body:HTMLBodyElement = <HTMLBodyElement>document.body;
    private _transition_container: HTMLElement = <HTMLElement>document.querySelector( '.c-main' );
    private _content_container: HTMLElement = <HTMLElement>document.querySelector( '.c-main__inner' );
    private _main_menu_container: HTMLElement = <HTMLElement>document.querySelector( '.c-main-nav__list');
    private _footer_main_menu_container: HTMLElement = <HTMLElement>document.querySelector( '.c-footer .c-main-nav__list' );
    private _content_hidden: boolean = false;

    private closeMenu: Event = document.createEvent( "Event" );
    // private openOverlay: Event = document.createEvent( "Event" );
    // private closeOverlay: Event = document.createEvent( "Event" );
    private newContentInserted: Event = document.createEvent( "Event" );
    private contentUpdated: CustomEvent = document.createEvent( "CustomEvent" );
    private contentFullyRevealed: CustomEvent = document.createEvent( "CustomEvent" );


    constructor() {

        this.closeMenu.initEvent( "closeMenu", false, true );
        // this.openOverlay.initEvent( "openOverlay", false, true );
        // this.closeOverlay.initEvent( "closeOverlay", false, true );
        this.newContentInserted.initEvent( "newContentInserted", false, true );
        this.contentUpdated.initEvent( "contentUpdated", false, true );
        this.contentFullyRevealed.initEvent( "contentFullyRevealed", false, true );

    }


    public hideContent( event?: CustomEvent ) {
        
        this._body.classList.add( 's-transition' );

        window.dispatchEvent( this.closeMenu );

        gsap.to( this._transition_container, { duration: .5, opacity: 0, ease: "power2.inOut", onComplete: () => {

            window.scrollTo(0,0);
            this._content_hidden = true;

        } } );

        this._content_container.style.pointerEvents = 'none';

        this.unloadVideos();

        // gsap.to( window, { duration: .4, scrollTo: 0, autoKill: true } );

    }


    public injectContent() {

        if ( this._content_hidden ) {
         
            let body_classes = history.state.bodyClasses;
            const new_content = history.state.markup[0];
            const new_main_menu = history.state.markup[1];
            const new_footer_main_menu = history.state.markup[2];

            this._body.classList.remove( 's-transition' );
           
            if ( this._body && ( body_classes || body_classes === '' ) ) {
                
                body_classes = body_classes.replace('s-initial-reveal', '');
                body_classes = `${body_classes} s-loader-hidden`;
                const is_touch: boolean = this._body.classList.contains( 'touch-device' );
                body_classes = ( is_touch ) ? `${body_classes} touch-device` : body_classes;
                
                this._body.setAttribute( 'class', body_classes );
         
            }

            if ( this._content_container && new_content ) {
         
                this._content_container.innerHTML = new_content;
     
            }

            if ( this._main_menu_container && new_main_menu ) {

                this._main_menu_container.innerHTML = new_main_menu;

            }

            if ( this._footer_main_menu_container && new_footer_main_menu ) {

                this._footer_main_menu_container.innerHTML = new_footer_main_menu;

            }
            

            // @ts-ignore
            window.SCROLL_DIRECTION = 'down';
            window.dispatchEvent( this.newContentInserted );
            window.dispatchEvent( this.contentUpdated );
            
            this._content_container.style.pointerEvents = 'auto';

            this._content_hidden = false;

        } else {
            
            setTimeout( () => {
            
                this.injectContent();
            
            }, 100 );
        
        }

    }


    public showContent() {

        // @ts-ignore
        gsap.to( this._transition_container, { duration: 1, delay: .2, opacity: 1, ease: "power2.inOut", onComplete: () => {
                
            window.dispatchEvent( this.contentFullyRevealed );

        } } );
    
    }


    private unloadVideos() {

        const videos: NodeListOf<HTMLVideoElement> = document.querySelectorAll( 'video' );
        for ( let i: number = 0; i < videos.length; i++ ) {

            const source_elements: NodeListOf<HTMLSourceElement> = videos[ i ].querySelectorAll( 'source' );
            for ( let j: number = 0; j < source_elements.length; j++ ) {

                source_elements[ j ].removeAttribute('src');

            }

            videos[ i ].pause();
            videos[ i ].preload = 'none';
            videos[ i ].setAttribute( 'preload', 'none' );

        }

    }

}