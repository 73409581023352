export class Header {

    private _body_element: HTMLElement;
    private _menu_toggle_element: HTMLElement;
    
    private _menu_open: boolean = false;
    private _scroll_position: number = 0;
    private _scroll_down_start: number = 0;
    private _scroll_disabled: boolean = false;
    private _scroll_top: number = 0;

    constructor() {

      this.init();

    }


    private init() {

        this._body_element = document.body;
        this._menu_toggle_element = document.querySelector( '.c-main-nav__toggle' );
        if ( this._menu_toggle_element ) {

          this._menu_toggle_element.addEventListener( 'click', ( event: Event ) => this.hamburgerClicked( event ) );
        //   this._menu_toggle_element.click();

        }

        window.addEventListener( 'scroll', ( event: Event) => this.handleScroll() );
        window.addEventListener( 'closeMenu', ( event: Event) => this.closeMenu() );

    }


    private hamburgerClicked( event: Event ) {

        event.preventDefault();

        this._menu_open = !this._menu_open;

        if ( this._menu_open) {

            this.disableScroll();
            document.body.classList.add( 's-menu-open' );
            document.body.classList.remove( 's-menu-closing' );

            this._menu_toggle_element.innerText = "Close";
            
        } else {
            
            this.closeMenu();

        }


        return false;
        
    }


    private closeMenu() {

        this._menu_open = false;

        document.body.classList.remove( 's-menu-open' );
        document.body.classList.add( 's-menu-closing' );
        setTimeout(() => {
            document.body.classList.remove( 's-menu-closing' );
        }, 1000);
        this.enableScroll();

        this._menu_toggle_element.innerText = "Menu";

    } 


    private handleScroll() {
        
        const scroll: number = window.pageYOffset;
        if ( scroll <= 0 ) {

            this._body_element.classList.remove( 's-header-bg-visible' );
            this._body_element.classList.remove( 's-header-hidden' );

        } else if ( this._scroll_position <= scroll  ) {

            // we are scrolling down
    
            // we check if we have started scrolling up already
            // if ( this._scroll_down_start > scroll ) {
            //     console.log('set new scroll down');
            //     // if we just started scrolling up, we set 
            //     // the this._scroll_down_start to the current scroll value
            //     this._scroll_down_start = scroll;
            // }
    
            // if we continue scrolling up, the difference between 
            // this._scroll_down_start and scroll will eventually reach a 100px
            if ( scroll - this._scroll_down_start > 0) {

                // @ts-ignore
                window.SCROLL_DIRECTION = 'down';

                this._body_element.classList.add( 's-header-hidden' );
                this._body_element.classList.remove( 's-header-bg-visible' );

            }
    
        } else {

            // we are scrolling up

            // @ts-ignore
            window.SCROLL_DIRECTION = 'up';

            this._scroll_down_start = window.pageYOffset;
            this._body_element.classList.remove( 's-header-hidden' );
            this._body_element.classList.add( 's-header-bg-visible' );

        }
        
        this._scroll_position = window.pageYOffset;

    }


    private disableScroll() {
    
        if ( this._scroll_disabled ) return;
        
        this._scroll_top = window.pageYOffset || document.documentElement.scrollTop;
        document.body.style.top = `${-1 * this._scroll_top}px`;

        this._scroll_disabled = true;

    }


    private enableScroll() {

        if ( !this._scroll_disabled ) return;
    
        window.scrollTo( 0, this._scroll_top );
        document.body.style.top = '';
    
        this._scroll_disabled = false;

    }

}