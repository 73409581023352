import '../../css/global/word_reveal.css';

export class WordReveal {

  private _delay: number = 40;
  private _options: object = {

    rootMargin: '0px',
  
  }


  constructor( elements: NodeListOf<HTMLElement> ) {

    for (let i: number = 0; i < elements.length; i++ ) this.setup( elements[ i ] );

  }


  private setup( element: HTMLElement ) {

    const word_observer: IntersectionObserver = new IntersectionObserver(
      ( entries, observer ) => this.handleWordIntersection( entries, observer ),
      this._options
    );

    const words: NodeListOf<HTMLSpanElement> = element.querySelectorAll( ':scope > span' );
    for (let i: number = 0; i < words.length; i++ ) word_observer.observe( words[ i ] );

  }


  private handleWordIntersection( entries, observer ) {

    for ( let i: number = 0; i < entries.length; i++ ) {

      if ( entries[ i ].isIntersecting ) {

        // @ts-ignore
        const delay = ( window.SCROLL_DIRECTION === 'down' ) ? i * this._delay : entries.length * this._delay - i * this._delay;
        setTimeout(() => {
          entries[ i ].target.classList.add( 's-revealed' );
        }, delay );
        
      } else {

        entries[ i ].target.classList.remove( 's-revealed' );
        
      }

    }

  }

}